export const PASSWORD_RULES = [
    {
        label: 'password-rule-length',
        regex: /^.{12,}$/,
    },
    {
        label: 'password-rule-upper-case',
        regex: /^(?=.*[A-Z]).+$/,
    },
    {
        label: 'password-rule-lower-case',
        regex: /^(?=.*[a-z]).+$/,
    },
    {
        label: 'password-rule-special-char',
        regex: /^(?=.*[*^[\]{}()?"!@$#.%&/\\,><':;|_~`=+\- ]).+$/,
    },
    {
        label: 'password-rule-no-leading-ending-spaces',
        regex: /^\S+.*\S+$/,
    },
];
