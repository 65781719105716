<script>
import CustomTitle from '@/components/Title';
import ContentBox from '@/components/ContentBox';
import { gql } from '@apollo/client/core';
import notify from '@/notify';
import FormToggle from '@/components/FormToggle';

export default {
    name: 'FiduciarySettings',
    props: {
        currentFiduciary: {
            type: Object,
        },
    },
    components: {
        CustomTitle,
        ContentBox,
        FormToggle,
    },
    data () {
        return {
            currentFiduciaryCopy: {},
        };
    },
    watch: {
        currentFiduciary () {
            this.createACurrentFiduciaryCopy();
        },
    },
    created () {
        this.createACurrentFiduciaryCopy();
    },
    emits: ['fiduciaryUpdated'],
    methods: {
        createACurrentFiduciaryCopy () {
            this.currentFiduciaryCopy = Object.assign({}, this.currentFiduciary);
        },
        async updateSendCodaAndSodaByMail () {
            this.currentFiduciaryCopy.sendCodaAndSodaByMail = !this.currentFiduciaryCopy.sendCodaAndSodaByMail;

            try {
                const { data } = await this.$apollo.mutate({
                    mutation: gql`mutation ($fiduciaryId: String, $input: FiduciaryUpdateInput!) {
                            updateFiduciary(fiduciaryId: $fiduciaryId, input: $input) {
                                data {
                                    sendCodaAndSodaByMail
                                }
                            }
                        }`,
                    variables: {
                        fiduciaryId: this.currentFiduciary.id,
                        input: {
                            sendCodaAndSodaByMail: this.currentFiduciaryCopy.sendCodaAndSodaByMail,
                        },
                    },
                });
                this.$emit('fiduciaryUpdated', data.updateFiduciary.data);
            } catch (e) {
                notify.error(this.$t('err-unknown'));
                this.createACurrentFiduciaryCopy();
            }
        },
    },
};
</script>

<template>
    <div>
        <CustomTitle class='mt-6 mb-6'>
            {{ $t('ttl-settings') }}
        </CustomTitle>
        <ContentBox>
            <FormToggle
                name='sendCodaAndSodaByMail'
                edit
                :label='$t("stngs-send-statement")'
                :info='$t("stngs-send-statement-info")'
                :value='currentFiduciaryCopy.sendCodaAndSodaByMail'
                @change='updateSendCodaAndSodaByMail'
            />
        </ContentBox>
    </div>
</template>
