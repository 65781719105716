import loader from '@/loader.js';
import store from '@/store';
import notify from '@/notify.js';
import { i18n } from '@/i18n.js';

function getUserRedirectUrl (path) {
    /* redirects from index and home */
    let url;
    if (path === '/' || path === '/home') {
        const organizations = store.state.user.organizationIds;
        const resellers = store.state.user.resellerIds;

        if (organizations.length > 0) {
            const previouslySelectedOrganizationId = localStorage['selectedOrganizationId'];
            // if the user has access that more than an organization
            if (previouslySelectedOrganizationId && organizations.some(orgId => orgId === previouslySelectedOrganizationId)) {
                // use in priority what is saved locally AND if the user has access
                url = { name: 'organization', params: { organizationId: previouslySelectedOrganizationId } };
            } else {
                // otherwise, take the first one
                url = { name: 'organization', params: { organizationId: organizations[0] } };
            }
        } else if (resellers.length > 0) {
            url = { name: 'rslr-fidu-search', params: { resellerId: resellers[0] } };
        } else if (path === '/') {
            // if the user has access to no organizations and no resellers, redirect him to the homepage
            url = { name: 'home' };
        }
    }
    return url;
}

async function redirectToLogin (path, next) {
    localStorage.setItem('redirect', path);
    await store.dispatch('logout');
    next('/login');
}

export const simpleCheckSession = async (to, from, next) => {
    /* Simple check user session: used for routes that doesn't required to load the full user (mostly not-authenticated routes) */
    try {
        let currentSession = await store.dispatch('checkSession');
        if (currentSession) {
            next('/');
        }
    } catch (e) {
    }
    next();
};

export const authGuard = async (to, from, next) => {
    /* Check user access and roles for restricted route */
    const loadId = loader.start();

    try {
        try {
            await store.dispatch('checkSession');
        } catch (e) {
            if (e === 'No current user') {
                if (to.path !== '/') {
                    // Don't display message when user is on index, because the user didn't try to access a restricted resource but user is still redirect to login page from index
                    notify.info(i18n.global.t('info-must-be-logged-in'));
                }
            } else if (e.message === 'Refresh Token has expired') {
                // Don't display unknown error when refresh token has expired and go straight to login page
                throw e;
            } else {
                notify.error(i18n.global.t('err-unknown'));
            }
            throw e;
        }

        try {
            // FIXME Route-refactoring: loadUserCached only fires once every 2.5 seconds and is cached whenever rapid
            //  subsequent calls are attempted.
            await store.dispatch('loadUserCached');
        } catch (e) {
            if (e.message === 'invalidUserConfiguration') {
                notify.error(i18n.global.t('err-login-invalid-user-configuration'));
            } else {
                notify.error(i18n.global.t('err-unknown'));
            }
            throw e;
        }

        const organizationId = to.params.organizationId;
        const resellerId = to.params.resellerId;

        // check restricted access based on entity type
        const restrictedToEntity = to.meta.authGuard.restrictedToEntity;
        if (
            restrictedToEntity &&
            (
                (restrictedToEntity === 'organization' && !store.state.user.organizationIds.includes(organizationId)) ||
                (restrictedToEntity === 'reseller' && !store.state.user.resellerIds.includes(resellerId))
            )
        ) {
            notify.error(i18n.global.t('err-restricted-access'));
            next({ name: 'index' });
        }

        // check restricted access based on user type (role) for organization
        // set authGuard.isCurrentOrgAdmin on meta to reuse it easily in component via $route
        to.meta.authGuard.isCurrentOrgAdmin = store.state.user.organizations.some(org => org.organizationId === organizationId && ['admin', 'support'].includes(org.role));

        const restrictedToOrgRole = to.meta.authGuard.restrictedToOrgRole;
        if (
            restrictedToOrgRole &&
            restrictedToOrgRole === 'admin' &&
            !to.meta.authGuard.isCurrentOrgAdmin
        ) {
            notify.error(i18n.global.t('err-restricted-access'));
            next({ name: 'index' });
        }

        // user redirection
        let redirect = localStorage.getItem('redirect') || getUserRedirectUrl(to.path);
        localStorage.removeItem('redirect');
        if (redirect) {
            next(redirect);
        } else {
            next();
        }
    } catch (e) {
        // Catch errors from Cognito and from loadUser
        console.error(e);
        await redirectToLogin(to.fullPath, next);
    }
    loader.stop(loadId);
};
