<script>
import CustomTitle from '@/components/Title';
import ContentBox from '@/components/ContentBox';

export default {
    name: 'FiduciaryInfo',
    props: {
        currentFiduciary: {
            type: Object,
        },
    },
    components: {
        CustomTitle,
        ContentBox,
    },
};
</script>

<template>
    <div>
        <CustomTitle class='mt-6 mb-6'>
            {{ $t('h-fidu-info') }}
        </CustomTitle>
        <content-box class='md:flex'>
            <div class='w-full'>
                <form>
                    <div class='form-group'>
                        <label>{{ $t('lbl-name') }}</label>
                        <p class='cb-form-value'>
                            {{ currentFiduciary.name }}
                        </p>
                    </div>
                    <div class='form-group'>
                        <label>{{ $t('lbl-software') }}</label>
                        <p class='cb-form-value'>
                            {{ currentFiduciary.softwareName }}
                        </p>
                    </div>
                </form>
            </div>
            <div class='w-full'>
                <form>
                    <div class='form-group'>
                        <label>{{ $t('lbl-contact-email-2') }}</label>
                        <p class='cb-form-value'>
                            {{ currentFiduciary.contactEmail }}
                        </p>
                    </div>
                    <div class='form-group'>
                        <label>{{ $t('lbl-language') }}</label>
                        <p class='cb-form-value'>
                            {{ currentFiduciary.language }}
                        </p>
                    </div>
                </form>
            </div>
        </content-box>
    </div>
</template>

<style scoped>
label {
    @apply ml-0;
}
</style>
