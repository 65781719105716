<script>
    import Popup from '@/clientcomponents/Popup.vue';
    import Loader from '@/loader';
    import notify from '@/notify';
    import router from '@/router';
    import validate from '@/validate';
    import {gql} from '@apollo/client/core';
    import {VoilaMandateSubscribedStates} from '@/constants/voila2';
    import DatePicker from '@/components/DatePicker.vue';
    import utils from '../utils';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import { Form } from 'vee-validate';
    import FormToggle from '@/components/FormToggle';
    import FormSelect from '@/components/FormSelect';
    import FormInput from '../components/FormInput.vue';

    export default {
        name: 'Client-PlatformTransfer',
        props: {
            relatedFiduciaries: Array,
            clientV2: Object,
        },
        components: {
            FormInput,
            Popup,
            DatePicker,
            FriendlyButton,
            // eslint-disable-next-line vue/no-reserved-component-names
            Form,
            FormToggle,
            FormSelect,
        },
        data () {
            return {
                show: false,
                saving: false,
                extraClientData: {},
                firstDate: null,
                lastDate: null,
            };
        },
        computed: {
            hasActiveVoilaService () {
                return !!this.extraClientData.currentVoilaMandate;
            },
            currentVoilaEmail () {
                return (
                    this.extraClientData.currentVoilaMandate &&
                    this.extraClientData.currentVoilaMandate.deliveryEmail
                ) ? this.extraClientData.currentVoilaMandate.deliveryEmail : null;
            },
            today () {
                let today = new Date();
                today.setHours(0, 0, 0, 0);
                return today;
            },
            codaDeliveryStartDateMaxDate () {
                return this.lastDate ? this.lastDate : this.today;
            },
            codaDeliveryStartDateMinDate () {
                return this.firstDate ? this.firstDate : this.today;
            },
        },
        methods: {
            async getExtraClientData () {
                try {
                    const extraClientData = await this.$apollo.query({
                        query: gql`query ExtraClientData($clientId: String) {
                            currentVoilaMandate(clientId:$clientId) {
                                 deliveryEmail,
                                 status,
                            },
                            bankAccounts(clientId:$clientId) {
                                iban,
                            }
                        }`,
                        variables: {
                            clientId: this.clientV2.id,
                        },
                    });

                    if (
                        extraClientData.data.currentVoilaMandate &&
                        !VoilaMandateSubscribedStates.includes(extraClientData.data.currentVoilaMandate.status)
                    ) {
                        extraClientData.data.currentVoilaMandate = null;
                    }

                    this.extraClientData = extraClientData.data;
                } catch (e) {
                    notify.error(this.$t('err-unknown'));
                }
            },
            async getCodaFileRange (clientId, bankAccounts) {
                /**
                 * Get a date range in which CODA files were received so the user can select a date in
                 * this range for the "codaDeliveryStartDate" field (date for resend CODA).
                 *
                 * Min limit for this codaDeliveryStartDate is 2 years ago.
                 * Max limit is today.
                 */
                const { data } = await this.$apollo.query({
                    query: gql`query codaFileRange($clientId: String, $bankAccounts: [String]!) {
                        codaFileRange(clientId: $clientId, bankAccounts:$bankAccounts) {
                            bankAccount
                            firstDate
                            lastDate
                        }
                    }`,
                    variables: {
                        clientId: clientId,
                        bankAccounts: bankAccounts,
                    },
                });
                if (data.codaFileRange && data.codaFileRange.length !== 0) {
                    const firstDates = data.codaFileRange.map(firstDates => { return new Date(firstDates.firstDate).setHours(0); });
                    this.firstDate = new Date(Math.min(...firstDates));

                    // If the firstDate of a CODA is received is more than 2 years, limit it to 2 years
                    // Normally, it should not happen because CODA files more than 2 years are deleted automatically.
                    // But we want to avoid issues in edge cases where the date of a file is more than 2 years or exactly from 2 years ago (exactly same day).
                    const twoYearsBefore = new Date();
                    twoYearsBefore.setFullYear(this.today.getFullYear() - 2);
                    // Min limit for codaDeliveryStartDate must be strictly greater than 2 years ago (>2yAgo):
                    twoYearsBefore.setDate(twoYearsBefore.getDate() + 1);
                    if (this.firstDate < twoYearsBefore) {
                        this.firstDate = twoYearsBefore;
                    }

                    const lastDates = data.codaFileRange.map(lastDates => { return new Date(lastDates.lastDate); });
                    this.lastDate = new Date(Math.max(...lastDates));
                }
            },
            formatDateAsString (value) {
                return utils.date2strYYYYMMDD(value);
            },
            async submitPlatformTransferRequest (values) {
                Loader.start();
                this.saving = true;

                // If voilaDeliveryEmail is filled in the form, be sure to set the original value for contactEmail in case of user updated the contactEmail in form
                // For some reason, user can't update contact email if it's not to use this new contactEmail for voilaDeliveryEmail
                let newContactEmail;
                if (values.voilaDeliveryEmail && values.voilaDeliveryEmail.length > 0) {
                    newContactEmail = this.clientV2.contactEmail;
                } else {
                    newContactEmail = values.contactEmail;
                }

                const newPlatformTransferRequestData = {
                    targetFiduciaryId: values.targetFiduciaryId,
                    clientId: this.clientV2.id,
                    enterpriseName: this.clientV2.enterpriseName,
                    enterpriseNumber: this.clientV2.enterpriseNumber,
                    hasBelgianVatNumber: this.clientV2.hasBelgianVatNumber,
                    representativeName: this.clientV2.representativeName,
                    representativeFunction: this.clientV2.representativeFunction,
                    address: this.clientV2.address,
                    address2: this.clientV2.address2,
                    zip: this.clientV2.zip,
                    city: this.clientV2.city,
                    clientCode: values.clientCode,
                    language: this.clientV2.language,
                    contactName: this.clientV2.representativeName,
                    contactEmail: newContactEmail,
                    exactEmail: values.exactEmail ? values.exactEmail : null,
                    codaDeliveryStartDate: values.codaDeliveryStartDate ? this.formatDateAsString(values.codaDeliveryStartDate) : null,
                    sendCodaAndSodaByMail: this.clientV2.sendCodaAndSodaByMail,
                    orderVoila: values.orderVoila ? values.orderVoila : false,
                    voilaDeliveryEmail: values.voilaDeliveryEmail ? values.voilaDeliveryEmail : null,
                    bankAccounts: this.extraClientData.bankAccounts.map(ba => { return ba.iban; }),
                    socialOffices: [],
                };

                try {
                    const createMutation = await this.$apollo.mutate({
                        mutation: gql`mutation ($input: ClientTransferRequestCreateInput!) {
                            createClientTransferRequest(input: $input) {
                                errors { code, detail, source { pointer } }
                            }
                        }`,
                        variables: {
                            input: newPlatformTransferRequestData,
                        },
                    });

                    const createResponse = createMutation.data.createClientTransferRequest;

                    if (createResponse.errors) {
                        const allErrors = createResponse.errors;

                        // Errors on fields of the form: errors with pointer to a field (ex: "/data/fieldName")
                        validate.reportGQLFieldErrors(allErrors, this.$refs.createPlatformTransferForm, {
                            'Already in use by a client of another fiduciary.': 'err-exact-email-not-unique',
                            'Invalid date: cannot be more than 2 years ago.': 'err-coda-delivery-start-date-min-2-years-ago',
                        });

                        // Global error: error with pointer to "/data"
                        const globalError = allErrors.find(error => {
                            return (
                                error.source && error.source.pointer && error.source.pointer === '/data'
                            );
                        });

                        if (globalError) {
                            if (globalError.code === 'alreadyExists' || globalError.code === 'alreadyExistsForFiduciary') {
                                // there is "alreadyExists" errors; display specific message
                                notify.error(this.$t('result-platform-transfer-request-failed-already-exists'));
                            } else {
                                // unexpected global error
                                notify.error(this.$t('result-platform-transfer-request-failed'));
                            }
                        }

                        // Bad client data errors
                        if (allErrors.find(error => error.code === 'blank' || error.code === 'null')) {
                            notify.error(this.$t('result-platform-transfer-request-failed-client-incomplete'));
                            this.close();
                        }
                    } else {
                        notify.success(this.$t('result-platform-transfer-request-success'));
                        this.close();
                        router.push(`/organization/${this.$route.params.organizationId}/environment/${this.$route.params.environmentId}/`);
                    }
                } catch (error) {
                    notify.error(this.$t('err-unknown'));
                }

                this.saving = false;
                Loader.stop();
            },
            voilaTogglesClick (activated) {
                if (!activated) {
                    this.$refs.createPlatformTransferForm.setFieldValue('voilaDeliveryEmail', '');
                }
            },
            async open () {
                await this.getExtraClientData();
                const bankAccounts = this.extraClientData.bankAccounts.map(ba => { return ba.iban; });
                await this.getCodaFileRange(this.clientV2.id, bankAccounts);
                this.show = true;
            },
            close () {
                this.show = false;
            },
            fillDate () {
                this.$refs.createPlatformTransferForm.setFieldValue('codaDeliveryStartDate', this.firstDate);
            },
        },
    };
</script>
<template>
    <Form
        ref='createPlatformTransferForm'
        tag='div'
        class='row box noborder'
        v-slot='{ values }'
        @submit='submitPlatformTransferRequest'
    >
        <Popup :show='show' :close='close'>
            <template #header>
                {{ $t('ttl-platform-transfer-request', {'client_name': clientV2.enterpriseName }) }}
            </template>

            <h4>{{ $t('ttl-select-software-to-transfer') }}</h4>

            <FormSelect
                name='targetFiduciaryId'
                id='targetFiduciaryId'
                :placeholder='$t("ttl-select-software-to-transfer")'
                edit
                rules='required'
                :options='relatedFiduciaries.map(relFidu => { return {label: relFidu.name, value: relFidu.id}; })'
            />

            <h4>{{ $t('ttl-provide-client-data') }}</h4>

            <FormInput
                name='clientCode'
                id='clientCode'
                :label='$t("lbl-client-code")'
                :placeholder='$t("lbl-client-code")'
                edit
                rules='max:50|required'
                :value='clientV2.clientCode'
            />

            <FormInput
                v-show='values.useContactEmailForVoila && hasActiveVoilaService'
                name='contactEmail'
                id='contactEmail'
                :label='$t("lbl-email")'
                :placeholder='$t("lbl-email")'
                edit
                rules='email'
                :value='clientV2.contactEmail'
            />

            <FormInput
                name='exactEmail'
                id='exactEmail'
                :label='$t("lbl-transfer-request-exact-email")'
                :placeholder='$t("lbl-email")'
                edit
                nullable
                rules='max:256'
            />

            <template v-if='hasActiveVoilaService'>
                <h4>
                    {{ $t('ttl-purchase-invoice-preference') }}
                    <div class='cb-legend'>
                        <i class='fa fa-info-circle'></i>
                        <div class='cb-legend-body'>
                            {{ $t('body-purchase-invoice-preference-info') }}
                        </div>
                    </div>
                </h4>

                <FormToggle
                    name='orderVoila'
                    @change='voilaTogglesClick'
                    :label='$t("lbl-keep-pi-service")'
                    edit
                    class='form-group'
                    :value='hasActiveVoilaService'
                />

                <div v-show='values.orderVoila'>
                    <FormToggle
                        name='useContactEmailForVoila'
                        :label='$t("lbl-transfer-pi-email")'
                        edit
                        @change='voilaTogglesClick'
                        class='form-group'
                        :value='!currentVoilaEmail'
                    />

                    <FormInput
                        v-show='!values.useContactEmailForVoila'
                        name='voilaDeliveryEmail'
                        id='voilaDeliveryEmail'
                        :label='$t("lbl-pi-email")'
                        :placeholder='$t("lbl-pi-email")'
                        edit
                        :rules='`email|max:254${values.orderVoila && !values.useContactEmailForVoila ? "|required" : "" }`'
                        :value='currentVoilaEmail'
                    />
                </div>
            </template>

            <DatePicker
                name='codaDeliveryStartDate'
                :label='$t("lbl-resend-coda-platform-transfer-title")'
                :placeholder='$t("lbl-resend-coda-transfer-date-placeholder")'
                :min-date='codaDeliveryStartDateMinDate'
                :max-date='codaDeliveryStartDateMaxDate'
                :start-date='codaDeliveryStartDateMaxDate'
                edit
                class='mt-6'
            >
                <template #info>
                    {{ $t('lbl-resend-coda-platform-transfer-info') }}
                </template>
            </DatePicker>
            <a class='mt-3 flex' v-if='!!firstDate' @click='fillDate()'>
                {{ $t('lbl-coda-range-platform-transfer', { firstDate : formatDateAsString(firstDate)} ) }}
            </a>
            <p class='mt-3' v-else>
                {{ $t('lbl-coda-range-platform-transfer-no-files') }}
            </p>

            <template #buttons>
                <FriendlyButton
                    label='btn-cancel'
                    :action='close'
                    symbol='times'
                    small
                    square
                    secondary
                />
                <FriendlyButton
                    label='btn-save'
                    type='submit'
                    small
                    symbol='check'
                    square
                    :disabled='saving'
                />
            </template>
        </Popup>
    </Form>
</template>
