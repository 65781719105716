
<script>
    import notify from '@/notify.js';
    import { Auth } from '@aws-amplify/auth';
    import FormInput from '@/components/FormInput.vue';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import validate from '@/validate.js';
    import {PASSWORD_RULES} from '@/constants/common';
    import { Form } from 'vee-validate';
    import Popup from '@/clientcomponents/Popup.vue';

    export default {
        name: 'ChangePassword',
        components: {
            FriendlyButton,
            FormInput,
            // eslint-disable-next-line vue/no-reserved-component-names
            Form,
            Popup,
        },
        data () {
            return {
                cognitoError: '',
                isChangePasswordOngoing: false,
                passwordRules: PASSWORD_RULES,
            };
        },
        methods: {
            isNewPasswordStrong (newPassword) {
                // TODO: better to move this as a validate rule
                return validate.isPasswordStrong(newPassword);
            },
            isChangePasswordSubmitDisabled (currentPassword, newPassword) {
                return (!currentPassword || !newPassword || !this.isNewPasswordStrong(newPassword));
            },
            checkPasswordRule (rgx, newPassword) {
                return validate.checkPasswordRule(rgx, newPassword);
            },
            async changePassword (values) {
                if (this.isChangePasswordOngoing) return;

                if (!this.isNewPasswordStrong(values.newPassword)) {
                    this.$refs.changePasswordForm.setErrors({
                        newPassword: [this.$t('val-password-not-strong')],
                    });
                    return;
                }

                this.isChangePasswordOngoing = true;

                // Change password in Cognito
                try {
                    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();

                    await Auth.changePassword(currentAuthenticatedUser, values.currentPassword, values.newPassword);

                    notify.success(this.$t('suc-change-password'));
                    this.$store.commit('closeUserSettingsChangePasswordPopup');
                } catch (err) {
                    if (err) {
                        if (err.name === 'NotAuthorizedException') {
                            this.$refs.changePasswordForm.setErrors({
                                currentPassword: [this.$t('err-invalid-password')],
                            });
                        } else if (err.name === 'LimitExceededException') {
                            this.cognitoError = this.$t('err-change-password-limit-exceeded');
                        } else {
                            this.cognitoError = this.$t('err-unknown-retry-later');
                        }
                    } else {
                        this.cognitoError = this.$t('err-unknown-retry-later');
                    }
                }

                this.isChangePasswordOngoing = false;
            },
        },
    };
</script>

<template>
    <Popup :show='$store.state.userSettingsPopups.changePassword' :close='() => { $store.commit("closeUserSettingsChangePasswordPopup") }'>
        <template #header>
            {{ $t('h-change-password') }}
        </template>
        <div class='change-password'>
            <div v-if='cognitoError' class='rounded-md bg-red-300 text-white mb-4 px-6 py-3'>
                {{ cognitoError }}
            </div>

            <Form tag='div' ref='changePasswordForm' @submit='changePassword' v-slot='{ values }'>
                <FormInput
                    autocomplete='current-password'
                    :label='$t("lbl-current-password")'
                    type='password'
                    name='currentPassword'
                    rules='required'
                    ref='currentPasswordInput'
                    edit
                />

                <FormInput
                    autocomplete='new-password'
                    :label='$t("lbl-new-password")'
                    type='password'
                    name='newPassword'
                    rules='required'
                    ref='newPasswordInput'
                    mode='aggressive'
                    edit
                />
                <div class='mt-6'>
                    <ul class='list-none p-0 text-blue-400'>
                        <li class='list-none' v-for='rule in passwordRules' :key='rule.label'>
                            <span
                                v-if='values.newPassword && values.newPassword.length > 0 && checkPasswordRule(rule.regex, values.newPassword)'
                                class='text-green-300'
                            >
                                <i class='fa fa-check mr-2'></i>{{ $t(rule.label) }}
                            </span>
                            <span v-else class='text-red-300'>
                                <i class='fa fa-times mr-2'></i>{{ $t(rule.label) }}
                            </span>
                        </li>
                    </ul>
                </div>

                <div class='flex justify-end mt-6 mb-3'>
                    <FriendlyButton
                        label='btn-change-password'
                        no-margin
                        fullwidth
                        square
                        small
                        type='submit'
                        :disabled='isChangePasswordSubmitDisabled(values.newPassword, values.newPassword)'
                    />
                </div>
            </Form>
        </div>
    </Popup>
</template>
