import { createStore } from 'vuex';
import Prismic from 'prismic-javascript';
import Api from '@/api';
import loader from '@/loader.js';
import utils from '@/utils.js';
import {getEnvVariables} from '@/api/env';
import {Auth} from '@aws-amplify/auth';
import {getUser} from '@/api/user';
import authUtils from '@/auth/utils';

const baseUser = {
    userId: '',
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    mfaMethod: '',
    organizationIds: [],
    organizations: [],
    environmentIds: [],
    environments: [],
    resellerIds: [],
    resellers: [],
};

const baseHelp = {
    loaded: false,
    navigation: [], // list of articles in display order for each locale
    articles: {},   // article content for each id.
    locales: {},    // mapping from article id to locales ids.
    invertLink: {}, // map localized id to document id
};

const basePopups = {
    loaded: false,
    navigation: [], // list of popups in display order for each locale,
    articles: {},   // popup content for each id,
    locales: {},    // mapping from popup id to locales ids
    visible: true,
};

const baseEnvVariables = {
    cognitoRegion: '',
    cognitoUserPoolId: '',
    cognitoClientId: '',
};

const store = createStore({
    state: {
        gui: 'desktop',
        user: Object.assign({}, baseUser),
        userSettingsPopups: {
            changePassword: false,
            '2fa': false,
        },
        allBanks: [],
        currentClientProblems: [],
        help: Object.assign({}, baseHelp),
        popups: Object.assign({}, basePopups),
        api: {
            url: '',
            label: '',
            list: [],
        },
        maintenance: false,
        flags: {},
        token: null,
        env: Object.assign({}, baseEnvVariables),
        lastUserFetchTime: null,
    },
    mutations: {
        reset (state) {
            authUtils.setAuthToken(null);
            authUtils.setIdToken(null);
            state.user = Object.assign({}, baseUser);
            state.help = Object.assign({}, baseHelp);
            state.popups = Object.assign({}, basePopups);
            state.currentClientProblems = [];
            state.env = Object.assign({}, baseEnvVariables);
        },

        setUser (state, {
            userId,
            username,
            email,
            firstName,
            lastName,
            mfaMethod,
            organizationIds,
            organizations,
            environmentIds,
            environments,
            resellerIds,
            resellers,
        }) {
            state.user.userId = userId;
            state.user.username = username;
            state.user.email = email;
            state.user.firstName = firstName;
            state.user.lastName = lastName;
            state.user.mfaMethod = mfaMethod;
            state.user.organizationIds = organizationIds;
            state.user.organizations = organizations;
            state.user.environmentIds = environmentIds;
            state.user.environments = environments;
            state.user.resellerIds = resellerIds;
            state.user.resellers = resellers;

            state.help = Object.assign({}, baseHelp);
            state.popups = Object.assign({}, basePopups);
            state.currentClientProblems = [];
        },

        setGui (state, gui) {
            state.gui = gui || 'desktop';
        },

        setAllBanks (state, banks) {
            state.allBanks = Object.freeze(banks);
        },

        setCurrentClientProblems (state, problems) {
            state.currentClientProblems = problems;
        },

        setHelpArticles: function (state, help) {
            state.help.navigation = help.navigation;
            state.help.articles = help.articles;
            var locales = {};
            var invertLink = {};
            for (var nav of help.navigation) {
                locales[nav.id] = nav;
                invertLink[nav.en_US] = nav.id;
                invertLink[nav.fr_FR] = nav.id;
                invertLink[nav.nl_BE] = nav.id;
            }
            state.help.locales = locales;
            state.help.invertLink = invertLink;
            state.help.loaded = true;
        },

        setPrismicPopups: function (state, popups) {
            state.popups.navigation = popups.navigation;
            state.popups.articles = popups.articles;
            let locales = {};
            for (var nav of popups.navigation) {
                locales[nav.id] = nav;
            }
            state.popups.locales = locales;
            state.popups.loaded = true;
        },
        setFlag (state, flag) {
            switch (flag) {
                case 'maintenance':
                    state.maintenance = true;
                    break;

                default:
                    state.flags[flag] = true;
                    break;
            }
        },
        removeFlag (state, flag) {
            if (state.flags[flag]) {
                delete state.flags[flag];
            }
        },
        hidePrismicPopups (state) {
            state.popups.visible = false;
        },
        setToken (state, token) {
            /** Auth token used in the new auth wrapper for API calls **/
            state.token = token;
        },
        setIdToken (state, token) {
            /** Id token from cognito, used in the new auth wrapper for API calls **/
            state.idToken = token;
        },
        setEnvVariables (state, {
            cognitoRegion,
            cognitoUserPoolId,
            cognitoClientId,
        }) {
            state.env.cognitoRegion = cognitoRegion;
            state.env.cognitoUserPoolId = cognitoUserPoolId;
            state.env.cognitoClientId = cognitoClientId;
        },
        setLastUserFetchTime (state, timestamp) {
            state.lastUserFetchTime = timestamp;
        },
        openUserSettingsChangePasswordPopup (state) {
            state.userSettingsPopups.changePassword = true;
        },
        closeUserSettingsChangePasswordPopup (state) {
            state.userSettingsPopups.changePassword = false;
        },
        openUserSettings2faPopup (state) {
            state.userSettingsPopups['2fa'] = true;
        },
        closeUserSettings2faPopup (state) {
            state.userSettingsPopups['2fa'] = false;
        },
    },
    actions: {
        async init (ctx) {
            // Fetch env variables
            try {
                const envVariables = (await (await getEnvVariables()).json());
                ctx.commit('setEnvVariables', envVariables);
            } catch (e) {
                ctx.commit('reset');
            }
        },
        setCurrentClientProblems (ctx, problems) {
            return ctx.commit('setCurrentClientProblems', problems);
        },
        setAllBanks (ctx, banks) {
            return ctx.commit('setAllBanks', banks);
        },
        async checkSession (ctx) {
            /**
             * Get current Cognito session and set tokens.
             *
             * If token are expired, it will also refresh the tokens if the refresh token is still valid.
             *
             */
            try {
                let session = await Auth.currentSession();
                authUtils.setAuthToken(session.accessToken.jwtToken);
                authUtils.setIdToken(session.idToken.jwtToken);
                return session;
            } catch (e) {
                if (e !== 'No current user') {
                    console.error(`Error while checking session. ${e}`);
                }
                throw e;
            }
        },
        async loadUserCached (ctx) {
            const currentTime = new Date().getTime();
            const lastFetchTime = ctx.state.lastUserFetchTime || 0;
            const timeElapsedSinceLastFetch = currentTime - lastFetchTime;

            if (timeElapsedSinceLastFetch >= 2500) {
                await ctx.dispatch('loadUser');
                ctx.commit('setLastUserFetchTime', currentTime);
            }
        },
        async loadUser (ctx) {
            try {
                const getUserResponse = await getUser();
                if (getUserResponse.ok) {
                    const {userInfo} = (await getUserResponse.json()).data;
                    ctx.commit('setUser', userInfo);
                } else {
                    const error = await getUserResponse.json();
                    if (error.errorCode && error.errorCode === 'invalidUserConfiguration') {
                        throw new Error('invalidUserConfiguration');
                    }

                    throw new Error(`HTTP error during user loading (status: ${getUserResponse.status}).`);
                }
            } catch (e) {
                if (e.message !== 'invalidUserConfiguration') {
                    // Don't display "expected" error
                    console.error(`Failed to load user. ${e}`);
                }
                throw e;
            }
        },
        async logout (ctx) {
            await Auth.signOut();
            ctx.commit('reset');
        },
        loadHelpArticles (ctx) {
            if (window.Cypress) {
                console.info('Prismic docs are disabled in unit tests');
                return;
            }
            var loadId = loader.start();

            return Api.getPrismicApi().then((api) => {
                var navigation = [];
                return api.getSingle('faq-homepage').then((res) => {
                    navigation.push(utils.prismicDocLocales(res));

                    return api.query(Prismic.Predicates.at('document.type', 'faq-article'));
                }).then((res) => {
                    for (var article of res.results) {
                        navigation.push(utils.prismicDocLocales(article));
                    }

                    var ids = [];
                    for (var entry of navigation) {
                        ids.push(entry.fr_FR);
                        ids.push(entry.nl_BE);
                        ids.push(entry.en_US);
                    }
                    ids = utils.uniques(ids);

                    return utils.loadMultiplePrismicArticles(api, ids);
                }).then((articles) => {
                    ctx.commit('setHelpArticles', {
                        navigation: navigation,
                        articles: articles,
                    });
                    loader.stop(loadId);
                }).catch((err) => {
                    console.error('Error Loading Help Articles', err);
                    loader.stop(loadId);
                });
            });
        },
        async loadPrismicPopups (ctx) {
            if (window.parent.Cypress) {
                console.info('Prismic popups are disabled in cypress tests');
                return;
            }
            let loadId = loader.start();
            try {
                let navigation = [];
                let api = await Api.getPrismicApi();
                let res = await api.query(Prismic.Predicates.at('document.type', 'popup'));
                navigation = res.results.map((article) => utils.prismicDocLocales(article));
                let ids = [];
                for (let entry of navigation) {
                    ids.push(entry.fr_FR);
                    ids.push(entry.nl_BE);
                    ids.push(entry.en_US);
                }
                ids = utils.uniques(ids);
                let articles = await utils.loadMultiplePrismicArticles(api, ids);
                ctx.commit('setPrismicPopups', {navigation, articles});
                loader.stop(loadId);
            } catch (err) {
                loader.stop(loadId);
                console.error('Error Loading Prismic Popup');
            }
        },
        setToken (ctx, token) {
            ctx.commit('setToken', token);
        },
        setIdToken (ctx, token) {
            ctx.commit('setIdToken', token);
        },
        openUserSettingsChangePasswordPopup (ctx) {
            ctx.commit('openUserSettingsChangePasswordPopup');
        },
        closeUserSettingsChangePasswordPopup (ctx) {
            ctx.commit('closeUserSettingsChangePasswordPopup');
        },
        openUserSettings2faPopup (ctx) {
            ctx.commit('openUserSettings2faPopup');
        },
        closeUserSettings2faPopup (ctx) {
            ctx.commit('closeUserSettings2faPopup');
        },
    },
});

export default store;
