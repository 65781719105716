<template>
    <component
        :is='link ? "a" : "button"'
        :type='type ? type : null'
        :href='link ? link : null'
        :class='
            `button ${variant ? variant : ""}
      ${noSlot() || hideText ? "icon-only" : ""}
      ${disabled ? "disabled" : ""}`
        '
        @click='click'
        :disabled='disabled'
        :style='color ? { "background-color": `#${color}` } : ""'
    >
        <div
            class='flex w-full'
            :class='`${reverseIcon ? "reverse flex-row-reverse" : ""}`'
        >
            <i
                :class='
                    `my-1 icon uil uil-${icon} ${
                        noSlot() || hideText ? "" : "icon--space"
                    }`
                '
                v-if='icon'
            ></i>
            <span :class='{ hide: hideText }' class='w-full text-center'>
                <slot></slot>
            </span>
        </div>
    </component>
</template>

<script>
export default {
    name: 'ButtonComponent',
    props: {
        icon: String,
        link: String,
        type: String,
        variant: String,
        hideText: Boolean,
        prevent: Boolean,
        stop: Boolean,
        reverseIcon: Boolean,
        disabled: Boolean,
        color: String,
    },
    emits: ['click'],
    methods: {
        noSlot () {
            return typeof this.$slots.default() !== 'object';
        },
        click (e) {
            if (this.prevent) {
                e.preventDefault();
            }
            if (this.stop) {
                e.stopPropagation();
            }
            this.$emit('click', e);
        },
    },
};
</script>

<style lang="postcss" scoped>
.button {
  @apply bg-blue-500 inline-block px-5 h-12 text-white outline-none inline-flex items-center relative border-none rounded-md;
  @apply transition duration-100 ease-in-out;

  &.icon-only {
    @apply p-5;
  }

  &:hover {
    @apply bg-blue-700;
  }

  &.no-padding {
    @apply p-0;
  }
}

.disabled {
  @apply cursor-not-allowed opacity-50;
}

.small {
  @apply px-4 py-2;

  &.icon-only {
    @apply p-4;
  }
}

.no-background {
  @apply bg-transparent text-blue-500;

  .loader {
    @apply bg-transparent;
    path {
      @apply fill-current;
    }
  }

  &:hover {
    @apply bg-transparent text-blue-700;
  }
}

.loader {
  @apply absolute inset-0 flex items-center justify-center bg-white bg-opacity-50;
}

.icon {
  @apply flex items-center;
}

.icon--space {
  @apply mr-2;
}

.reverse .icon--space {
  @apply ml-2;
}

.hide {
  @apply text-opacity-0 pointer-events-none absolute;
  top: -999em;
}

.transparent {
  @apply bg-transparent text-blue-500;

  &:hover {
    @apply bg-transparent text-blue-700;
  }
}

.dark {
  @apply text-grey-700;

  &:hover {
    @apply text-blue-700;
  }
}

.grey {
  @apply bg-grey-300;
}

.red {
  background-color: rgba(255, 87, 51, 0.7);
}
</style>
