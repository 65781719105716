<script>
    export default {
        name: 'PopupComponent',
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            close: {
                type: Function,
                required: true,
            },
            small: Boolean,
            large: Boolean,
            extraLarge: Boolean,
            fixedHeight: Boolean,
            vid: String,
            notClosable: Boolean,
        },
        computed: {
            widthClass () {
                if (this.small) {
                    return 'container md:w-1/2';
                } else if (this.large) {
                    return 'container md:w-full';
                } else if (this.extraLarge) {
                    return 'md:w-4/5';
                } else {
                    return 'container md:w-2/3';
                }
            },
        },
        mounted () {
            document.body.addEventListener('keydown', this.escKeyListener);
        },
        beforeUnmount () {
            document.body.removeEventListener('keydown', this.escKeyListener);
        },
        methods: {
            closePopup () {
                if (this.notClosable) return;
                if (this.show) {
                    this.close();
                }
            },
            escKeyListener (event) {
                if (event.key === 'Escape') {
                    this.closePopup();
                }
            },
        },
    };
</script>

<template>
    <div class='popup' :class='{"fixed-height": fixedHeight, "pointer-events-none": !show, "pointer-events-auto": show }'>
        <Transition>
            <div class='popup-bg' @click='closePopup' v-if='show'></div>
        </Transition>
        <Transition>
            <div
                class='popup-dialog-container'
                v-if='show'
                :id='vid'
            >
                <div class='popup-main' :class='widthClass'>
                    <span class='popup-close' @click='close' v-if='!notClosable'>
                        <i class='fa fa-close text-blue-400'></i>
                    </span>
                    <h1 class='popup-header' v-if='$slots.header'>
                        <slot name='header'></slot>
                    </h1>
                    <div class='popup-content'>
                        <slot></slot>
                    </div>
                    <div class='popup-alert' v-if='$slots.alert'>
                        <slot name='alert'></slot>
                    </div>
                    <div class='popup-buttons mt-8' v-if='$slots.buttons'>
                        <slot name='buttons'></slot>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<style scoped lang='postcss'>
.popup {
    @apply fixed inset-0;
    z-index: 3010;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all linear 300ms;
}

.popup-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .1);
    backdrop-filter: blur(3px);
    z-index: 1;
    opacity: 1;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.2s ease-in-out;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.popup-dialog-container {
    @apply w-full;
    position: relative;
    min-height: 100%;
    z-index: 2;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.popup-main {
    @apply m-6 mb-24 shadow-xl relative;
    background: white;
    padding: 40px 50px;
    border-radius: 5px;

    @screen md {
        @apply mx-12;
    }
}

.popup-header {
    position: relative;
    margin: 0;
    padding-right: 50px;
    margin-bottom: 20px;
}

.popup-close {
    @apply h-10 w-10 transition-all text-lg hover:bg-blue-100 flex items-center justify-center top-3 right-3 rounded;
    position: absolute;
    text-align: center;
    cursor: pointer;
    color: #5f86ff;
    transition: top ease 100ms;
    user-select: none;
}

.popup-buttons {
    display: flex;
    justify-content: flex-end;
}
.popup-alert {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
}
@media (max-width: 767px) {
    .popup-header {
        font-size: 18px;
    }
    .popup-main {
        max-width: 100%;
        padding: 20px;
    }
    .popup-content {
        padding-right: 10px;
        max-height: 80vh;
    }
    .popup-dialog-container {
        top: 0;
        bottom: 0;
    }

    .fixed-height .popup-content {
            height: 80vh;
    }
}

.fixed-height .popup-content {
    height: 60vh;
}
</style>
