<script>
    import ListView from '@/components/ListView.js';
    import Exporter from '@/components/Exporter.vue';
    import ClientLink from '@/components/ClientLink.vue';
    import VerifiedEmail from '@/components/VerifiedEmail.vue';
    import ExactEmail from '@/components/ExactEmail.vue';
    import EnterpriseNumber from '@/components/EnterpriseNumber.vue';
    import ContentBox from '@/components/ContentBox.vue';
    import CustomTitle from '@/components/Title';
    import { dateFormat } from '@/filters';

    export default {
        name: 'ClientSearch',
        mixins: [ListView],
        props: {
            currentFiduciary: {
                type: Object,
            },
        },
        components: {
            'exporter':       Exporter,
            'clientlink':     ClientLink,
            VerifiedEmail,
            'exact-email':  ExactEmail,
            'enterprise-num': EnterpriseNumber,
            ContentBox,
            CustomTitle,
        },
        data () {
            var data = this.listViewData({
                apiModel: 'bff/api/search/clients',
                defaultSortKey: 'client_code',
                defaultFilters: {'state': 'active'},
            });

            this.configureSelectFilter(data, 'exactOnline', [
                {
                    value: 'allclients',
                    label: 'lbl-exact-email',
                    filter: null,
                },
                {
                    value: 'withexactemail',
                    label: 'opt-with-exact-email',
                    filter: {'has_exact_email': 'True'},
                }, {
                    value: 'withoutexactemail',
                    label: 'opt-without-exact-email',
                    filter: {'has_exact_email': 'False'},
                },
            ]);

            this.configureSelectFilter(data, 'contactemails', [
                {
                    value: 'allclients',
                    label: 'opt-contact-emails',
                    filter: null,
                },
                {
                    value: 'withemails',
                    label: 'opt-with-contact-email',
                    filter: {'has_contact_email': 'True'},
                },
                {
                    value: 'noemails',
                    label: 'opt-without-contact-email',
                    filter: {'has_contact_email': 'False'},
                },
            ]);

            return data;
        },
        computed: {
            isFiduExactOnline () {
                return this.currentFiduciary.isExactOnline;
            },
            isFiduEnterprise () {
                return this.currentFiduciary.isEnterprise;
            },
        },
        watch: {
            'currentFiduciary.id': function () {
                this.search();
            },
        },
        mounted () {
            this.watchFilters();
            this.setupPathFilters();
            this.search();
        },
        methods: {
            dateFormat: dateFormat,
            dynamicSearchParams () {
                return { 'fiduciaryId': this.currentFiduciary.id };
            },
            setupPathFilters () {
                if (this.$route.name === 'fidu-client-search-no-purchases') {
                    this.setSelectFilterValue('contactemails', 'noemails');
                }
            },
            hasSubscribedVoilaService (record) {
                const state = record.purchase_invoice_service_state;
                return state === 'ordered' || state === 'active';
            },
        },
    };
</script>

<template>
    <div>
        <div class='cb-fidu-title'>
            <div class='cb-fidu-actions'>
                <exporter model='clients' :fiduciary-id='currentFiduciary.id'>
                    <span>{{ $t('btn-export-clients') }}</span>
                </exporter>
            </div>
            <CustomTitle class='mt-6 mb-6'>
                {{ $t('h-clients') }}
            </CustomTitle>
        </div>

        <content-box>
            <form @submit.prevent.stop class='form-inline clearfix cb-fidu-search'>
                <searchbox :list='list' />

                <selector :filter='true' :model='list.filters.exactOnline' v-if='isFiduExactOnline' />
                <selector :filter='true' :model='list.filters.contactemails' />

                <div class='cb-search-uppernav'>
                    <pagination
                        :list='list'
                        :pagination='pagination'
                        :ctrls='getControls()'
                        size='small'
                    />
                </div>
            </form>

            <div class='cb-bordered' :class='{"table-responsive": $store.state.gui === "mobile"}'>
                <table class='table cb-searchresults'>
                    <colgroup>
                        <collumn class='cb-col-lg' :sorting='sorting' skey='client_code' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='enterprise_num' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='contact_email' />
                        <collumn class='cb-col-md' v-if='isFiduExactOnline' :sorting='sorting' skey='exact_email' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='state_modified_at' />
                        <col class='cb-col-xl'>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <span>{{ $t('th-client-code') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='client_code' />
                            </th>
                            <th>
                                <span>{{ $t('th-client-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='name' />
                            </th>
                            <th>
                                <span>{{ $t('th-enterprise-number') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='enterprise_num' />
                            </th>
                            <th>
                                <span>{{ $t('th-client-email') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='contact_email' />
                            </th>
                            <th v-if='isFiduExactOnline'>
                                <span>{{ $t('lbl-exact-email') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='exact_email' />
                            </th>
                            <th>
                                <span>{{ $t('th-creation-date') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='state_modified_at' />
                            </th>
                            <th>
                                <span>{{ $t('th-actions') }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='record in list.records' :key='record.id'>
                            <td>
                                <clientlink :client='record' />
                            </td>
                            <td>{{ record.name }}</td>
                            <td>
                                <enterprise-num :enterprise-number='record.enterprise_num' />
                            </td>
                            <td class='max-w-xs'>
                                <VerifiedEmail :email='record.contact_email' :verification-state='record.contact_email_verification_state' />
                            </td>
                            <td v-if='isFiduExactOnline' class='max-w-xs'>
                                <exact-email :email='record.exact_email' />
                            </td>
                            <td>{{ dateFormat(record.state_modified_at) }}</td>
                            <td>
                                <router-link
                                    class='btn btn-alt btn-xs btn-action'
                                    :to='{name:"fidu-client-uid-coda", params:{ uid: (record.id || record.uuid)}}'
                                >
                                    {{ $t('btn-new-coda-mandate') }}
                                </router-link>
                                <router-link
                                    class='btn btn-alt btn-xs btn-action'
                                    :to='{name:"fidu-client-uid-soda", params:{ uid: (record.id || record.uuid)}}'
                                >
                                    {{ $t('btn-new-soda-mandate') }}
                                </router-link>
                                <router-link
                                    class='btn btn-alt btn-xs btn-action'
                                    v-if='!hasSubscribedVoilaService(record)'
                                    :to='{name:"fidu-client-uid-voila", params:{ uid: (record.id || record.uuid)}}'
                                >
                                    {{ $t('btn-subscribe-purchase-invoice-service') }}
                                </router-link>
                                <template v-else-if='!isFiduEnterprise'>
                                    <router-link
                                        class='btn btn-alt btn-xs btn-action'
                                        :to='{name:"fidu-client-uid-voila", params:{ uid: (record.id || record.uuid)}}'
                                    >
                                        {{ $t('btn-manage-voila') }}
                                    </router-link>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <listloader :list='list' />

            <div class='text-center' v-if='!list.loading'>
                <pagination
                    :list='list'
                    :pagination='pagination'
                    :ctrls='getControls()'
                    scroll='scrollTop'
                    no-margin
                />
            </div>
        </content-box>
    </div>
</template>

<style scoped>
.btn-action {
    margin-right: 2px;
}
</style>
